import { Box } from 'components/Core'

// eslint-disable-next-line no-undef
export const apiPath = process.env.REACT_APP_API_PATH
export const paymnetPath = process.env.REACT_APP_PAYMENT_PATH
export const meetingSdkKey = process.env.REACT_APP_MEETING_SDK_KEY
export const paymentKey = process.env.REACT_APP_PAYMENT_KEY
export const threeDSecure = process.env.REACT_APP_PAYMENT_3D_SECURE
export const PRIVACY_POLICY_LINK = process.env.REACT_APP_PRIVACY_POLICY_LINK
export const TERMS_OF_USE_LINK = process.env.REACT_APP_TERMS_OF_USE_LINK
export const CALCOM_ADVISOR_CHILD_LINK = process.env.REACT_APP_CAL_COM_LINK_CHILD
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT
export const APPLE_PAY_MERCHANT_ID = process.env.REACT_APP_APPLE_PAY_MERCHANT_ID
export const APPLE_PAY_MERCHANT_DOMAIN = process.env.REACT_APP_APPLE_PAY_MERCHANT_DOMAIN

export const availableChatTypesForUserRole = {
  parent: [
    { value: 'therapist', name: 'therapist' },
    { value: 'advisor', name: 'advisor' },
  ],
  therapist: [
    { value: 'parent', name: 'client' },
    { value: 'advisor', name: 'advisor' },
  ],
  advisor: [
    { value: 'therapist', name: 'therapist' },
    { value: 'parent', name: 'client' },
  ],
  admin: [{ value: 'advisor', name: 'advisor' }],
}

//Regex
export const EMAIL_REGEX = /^[_a-z0-9-\+-]+(\.[_a-z0-9-\+-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@#$%^&*]{8,}$/

export const userTypes = {
  parent: 'parent',
  therapist: 'therapist',
  advisor: 'advisor',
  admin: 'admin',
}

export const screeningType = {
  discovery: 'discovery',
  therapy: 'therapy',
}
export const JoinableMeetingStatus = ['In progress', 'Planned']

export const mainStatementAnswers = {
  yes: 'Yes',
  no: 'No',
}

export const sessionStatus = {
  Completed: 'Completed',
  Cancelled: 'Cancelled',
  Planned: 'Planned',
  'In progress': 'In progress',
  'Pending Payment': 'Pending Payment',
  'Canceled - Payment Canceled': 'Canceled - Payment Canceled',
}

export const OpenSessionNotsWhen = [sessionStatus.Completed, sessionStatus['In progress'], sessionStatus.Planned]

export const objectiveTypes = {
  QUANTITATIVE: 'Quantitative',
  QUALITATIVE: 'Qualitative',
}

export const objectivesDropDownList = [{ value: objectiveTypes.QUANTITATIVE }, { value: objectiveTypes.QUALITATIVE }]

export const objectiveStatus = {
  NOT_STARTED: 'Not Started',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
}

export const objectiveStatusDropDownList = Object.values(objectiveStatus).map((status) => ({ value: status }))
export const objectiveInitialValue = {
  id: undefined,
  type: '',
  description: '',
  title: '',
  baseline_percentage: undefined,
  target_percentage: undefined,
  is_deleted: false,
}

export const qualitativeSelectOptions = [{ value: 'Some Improvement' }, { value: 'Little Improvement' }, { value: 'No Improvement' }]
export const goalStatus = {
  ACCOMPLISHED: 'Accomplished',
  NOT_ACCOMPLISHED: 'Not Accomplished',
}

export const goalStatusDropDownList = Object.values(goalStatus).map((status) => ({ value: status }))
