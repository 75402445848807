import Styles from './Styles.module.scss'
import { getInitials } from 'utils'
type Props = {
  name: string | undefined
}
export const ProfileName = ({ name }: Props) => {
  return (
    <div className={Styles.main_container__header}>
      <div className={Styles.main_container__header__initials}>{getInitials(name)}</div>
      <h6>{name}</h6>
    </div>
  )
}
