import { styled } from '@mui/material/styles'
import ExpandMore from '@mui/icons-material/ExpandMore'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { useState } from 'react'
import { Box, CenterRow, Flex, Text } from 'components/Core'
import { Progress } from './slice/types'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { dateFormatter } from 'utils/utils'
import { objectiveTypes } from 'constants/index'
import { theme } from 'utils/theme'
import { CustomTabs } from 'components/shared/Tabs'
import { QuantitativeProgressChart } from './QuantitativeProgressChart'

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  // borderTop: `1px solid ${theme.palette.divider}`,
  // borderBottom: `1px solid ${theme.palette.divider}`,
  backgroundColor: 'transparent',

  paddingLeft: 0,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => <MuiAccordionSummary expandIcon={<ExpandMore sx={{ fontSize: '1.5rem' }} />} {...props} />)(({ theme }) => ({
  backgroundColor: 'transparent',
  border: 'none',
  paddingLeft: 0,
  paddingRight: 0,
  justifyContent: 'space-between',
  ':hover': {
    backgroundColor: theme.palette.grey[200],
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {},
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: 'none',
}))

type Props = {
  progresses: Progress[] | undefined
  type: string | undefined
  baselinePercentage: number | undefined
  targetPercentage: number | undefined
  objectiveCreatedAt: string
}
export const Progresses = ({ progresses, type, baselinePercentage, targetPercentage, objectiveCreatedAt }: Props) => {
  const [expanded, setExpanded] = useState<boolean>()

  const handleChange = () => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded)
  }
  return (
    <Accordion sx={{ width: '100%' }} expanded={expanded} onChange={handleChange}>
      <AccordionSummary sx={{ gap: 2 }} aria-controls="panel1d-content">
        <Text textAlign="right" width="100%" variant="body1">
          Progresses
        </Text>
      </AccordionSummary>
      <AccordionDetails sx={{ backgroundColor: 'white', borderRadius: '10px' }}>
        {type === objectiveTypes.QUALITATIVE && <QualitativeProgressTable progresses={progresses} />}
        {type === objectiveTypes.QUANTITATIVE && (
          <CustomTabs
            tabs={[
              {
                value: 'chart',
                label: 'Chart View',
                component: (
                  <QuantitativeProgressChart
                    objectiveCreatedAt={objectiveCreatedAt}
                    progresses={progresses}
                    baselinePercentage={baselinePercentage}
                    targetPercentage={targetPercentage}
                  />
                ),
              },
              {
                value: 'table',
                label: 'Table View',
                component: <QuantitativeProgressTable baselinePercentage={baselinePercentage} targetPercentage={targetPercentage} progresses={progresses} />,
              },
            ]}
          />
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export function QualitativeProgressTable({ progresses }: { progresses: Progress[] | undefined }) {
  const rows = progresses?.map((progress) => ({
    date: progress.session.date,
    accuracy: progress.qualitative_value,
  }))
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Session</TableCell>
            <TableCell>Comment</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row) => (
            <TableRow key={row.date} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                {dateFormatter({ date: row.date })}
              </TableCell>
              <TableCell>{row.accuracy}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
type QuantitativeTableProps = { progresses: Progress[] | undefined; baselinePercentage: number | undefined; targetPercentage: number | undefined }
const QuantitativeProgressTable = ({ progresses, baselinePercentage, targetPercentage }: QuantitativeTableProps) => {
  const rows = progresses?.map((progress) => ({
    date: progress.session.date,
    accuracy: progress.quantitative_value ?? progress.qualitative_value,
  }))
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Session</TableCell>
            <TableCell>Percentage(%)</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold', color: theme.colors.typePurpure[300] }}>Baseline Percentage</TableCell>
            <TableCell sx={{ fontWeight: 'bold', color: theme.colors.typePurpure[300] }}>{baselinePercentage}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row) => (
            <TableRow key={row.date} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell sx={{ pl: 10 }} component="th" scope="row">
                {dateFormatter({ date: row.date })}
              </TableCell>
              <TableCell>{row.accuracy}</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold', color: theme.colors.typePurpure[300] }}>Target Percentage</TableCell>
            <TableCell sx={{ fontWeight: 'bold', color: theme.colors.typePurpure[300] }}>{targetPercentage}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
