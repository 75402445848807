import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store/type'

const rootState = (state: RootState) => state

export const selectTreatmentPlan = createSelector([rootState], (state) => state.treatmentPlan)

export const selectObjectiveType = createSelector([rootState], (state) => state.treatmentPlan.objectiveType)

export const selectGoal = createSelector([rootState], (state) => state.treatmentPlan.goal)
export const selectObjectives = createSelector([rootState], (state) => state.treatmentPlan.goal.objectives)
export const selectGoalFormOpened = createSelector([selectTreatmentPlan], (state) => state.openGoalForm)
export const selectProfileId = createSelector([rootState], (state) => state.treatmentPlan.profile_id)
