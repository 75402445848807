import type { Objective } from './slice/types'
import * as yup from 'yup'
import { objectiveTypes } from 'constants/index'

export const createTreatmentPlanValidation = (objectives: Array<Objective>) => {
  const dynamicSchema: any = {}

  for (let i = 0; i < objectives.length; i++) {
    dynamicSchema[`objective-status-${i}`] = yup
      .object()
      .nullable()
      .test(`custom-objective-status-${i}`, 'Objective status is required', (value: any) => {
        if (objectives[i]?.is_deleted) {
          return true
        }
        if (objectives[i].id && !value?.value) {
          return false
        }
        return true
      })
    dynamicSchema[`objective-type-${i}`] = yup
      .object()
      .nullable()
      .required('Objective type is required')
      .test(`custom-objective-type-${i}`, 'Objective type is required', (value: any) => {
        if (objectives[i]?.is_deleted) {
          return true
        }
        if (value?.value) {
          return true
        }
        return false
      })
    dynamicSchema[`objective-title-${i}`] = yup.string().test(`custom-objective-title-${i}`, 'Objective title is required', (value: any) => {
      if (objectives[i]?.is_deleted) {
        return true
      }
      if (value) {
        return true
      }
      return false
    })
    dynamicSchema[`objective-baseline-percentage-${i}`] = yup
      .string()

      .test(`custom-objective-baseline-percentage-${i}`, 'Baseline percentage is required', (value: any, context: any) => {
        const objectiveType = context?.from?.[0]?.value?.[`objective-type-${i}`]
        let notRequired = false
        if (objectives[i]?.is_deleted) {
          notRequired = true
        }
        if (objectiveType === objectiveTypes.QUANTITATIVE) {
          if (value) {
            notRequired = true
          }
          notRequired = false
        } else {
          notRequired = true
        }
        return notRequired
      })
    dynamicSchema[`objective-target-percentage-${i}`] = yup.string().when(`objective-type-${i}`, {
      is: (value: any) => value?.value === objectiveTypes.QUANTITATIVE,
      then: yup
        .string()
        .test('greater-than-baseline', 'Target must be greater than baseline', function (target) {
          if (objectives[i]?.is_deleted) {
            return true
          }
          const baseline = this.parent[`objective-baseline-percentage-${i}`]
          return !baseline || !target || Number(target) > Number(baseline)
        })
        .test(`custom-objective-target-percentage-${i}`, 'Target percentage is required', (value: any, context: any) => {
          const objectiveType = context?.from?.[0]?.value?.[`objective-type-${i}`]
          let notRequired = false
          if (objectives[i]?.is_deleted) {
            notRequired = true
          }
          if (objectiveType === objectiveTypes.QUANTITATIVE) {
            if (value) {
              notRequired = true
            }
            notRequired = false
          } else {
            notRequired = true
          }
          return notRequired
        }),
    })
  }

  return yup.object({
    title: yup.string().required('Goal title is required'),
    description: yup.string(),
    ...dynamicSchema,
  })
}
