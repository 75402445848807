import type { Direction } from 'components/Core/common/types'
import { userTypes } from 'constants/index'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

type Options = { userPreferredLanguage?: string; userType?: keyof typeof userTypes }
export const useGetLanguage = (options?: Options) => {
  const [searchParams] = useSearchParams()
  const [language, setLanguage] = useState('')
  const localStorageLanguage = localStorage.getItem('language')
  const queryLanguage = searchParams.get('lng')
  useEffect(() => {
    if (queryLanguage) {
      setLanguage(queryLanguage as string)
      localStorage.setItem('language', queryLanguage)
    } else {
      if (localStorageLanguage) {
        setLanguage(localStorageLanguage)
      } else {
        localStorage.setItem('language', options?.userPreferredLanguage ?? 'en')
        setLanguage(options?.userPreferredLanguage ?? 'en')
      }
    }
  }, [localStorageLanguage, options?.userPreferredLanguage, queryLanguage])
  const isArabic = language === 'ar'
  const direction = (isArabic ? 'rtl' : 'ltr') as Direction

  useEffect(() => {
    if (language) {
      localStorage.setItem('language', language)
    }
  }, [language])

  return { language, isArabic, direction }
}
