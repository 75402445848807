import { useDispatch, useSelector } from 'react-redux'
import { selectGoalFormOpened } from '../selectors'
import { treatmentPlanActions } from '..'

export const useGoalFormController = () => {
  const goalFormOpened = useSelector(selectGoalFormOpened)
  const dispatch = useDispatch()
  const openGoalForm = () => {
    dispatch(treatmentPlanActions.openGoalForm())
  }
  const closeGoalForm = () => {
    dispatch(treatmentPlanActions.closeGoalForm())
  }
  const toggleGoalForm = () => {
    dispatch(treatmentPlanActions.toggleGoalForm())
  }
  return {
    openGoalForm,
    closeGoalForm,
    toggleGoalForm,
    goalFormOpened,
  }
}
