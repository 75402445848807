import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

import { useMemo } from 'react'
import type { Objective } from './slice/types'
import { Box, Text } from 'components/Core'

type Props = {
  profile_id: number | undefined
  objectiveValue: Objective | undefined
  modalOpened: boolean
  selectedObjectives: Array<number | undefined>
  updateProgress: (objective: Objective) => void
  objectives: Objective[] | undefined
  disabled: boolean
}
export function ObjectivesSelector({ profile_id, selectedObjectives, objectiveValue, disabled, updateProgress, objectives }: Props) {
  const options = useMemo(() => {
    if (!objectives) return []
    return objectives
      ?.map((item) => {
        return {
          ...item,
          goalTitle: item.goal?.title,
        }
      })
      ?.filter((item) => !selectedObjectives.includes(item.id as number))
  }, [objectives, selectedObjectives])

  return (
    <Box>
      <Autocomplete
        disabled={disabled}
        //@ts-ignore
        options={options?.sort((a, b) => -b?.goalTitle?.localeCompare(a?.goalTitle as string))}
        groupBy={(option) => option?.goalTitle ?? ('' as string)}
        getOptionLabel={(option) => option?.title ?? ''}
        sx={{ width: '100%' }}
        renderInput={(params) => <TextField {...params} label="Objective" />}
        onChange={(_, value) => {
          updateProgress(value!)
        }}
        value={{ ...objectiveValue, goalTitle: objectiveValue?.goal?.title }}
        aria-required
      />
      {/* {!objectiveValue && modalOpened && (
        <Text color="error" variant="body2">
          Objective is required
        </Text>
      )} */}
    </Box>
  )
}
