import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { Goal, Objective, TreatmentPlanState } from './types'
import { objectiveTypes } from 'constants/index'
import { useDispatch, useSelector } from 'react-redux'

const initialState: TreatmentPlanState = {
  objectiveType: '',
  openGoalForm: false,
  profile_id: undefined,
  goal: {
    id: undefined,
    title: '',
    status: undefined,
    description: '',
    objectives: [
      {
        id: undefined,
        type: '',
        description: '',
        // progresses: [],
        title: '',
        baseline_percentage: undefined,
        target_percentage: undefined,
        is_deleted: false,
      },
    ],
  },
}

export const treatmentPlanSlice = createSlice({
  name: 'treatmentPlan',
  initialState,
  reducers: {
    setProfileId: (state, action: PayloadAction<number>) => {
      state.profile_id = action.payload
    },
    setObjectiveType: (state, action: PayloadAction<string>) => {
      state.objectiveType = action.payload
    },
    addObjectives(state, action: PayloadAction<Objective>) {
      state.goal.objectives.push(action.payload)
    },
    updateObjective(state, action: PayloadAction<{ value: Objective; index: number }>) {
      const { index, value } = action.payload
      if (value.type === objectiveTypes.QUALITATIVE) {
        state.goal.objectives[index] = {
          ...state.goal.objectives[index],
          ...value,
          baseline_percentage: undefined,
          target_percentage: undefined,
        }
        return
      }
      state.goal.objectives[index] = { ...state.goal.objectives[index], ...value }
    },
    hardDeleteObjective(state, action: PayloadAction<number>) {
      state.goal.objectives.splice(action.payload, 1)
    },
    softDeleteObjective(state, action: PayloadAction<number>) {
      state.goal.objectives[action.payload].is_deleted = true
    },
    updateGoal(state, action: PayloadAction<Partial<Omit<Goal, 'objectives'>>>) {
      state.goal = { ...state.goal, ...action.payload }
    },
    toggleGoalForm(state) {
      state.openGoalForm = !state.openGoalForm
    },
    openGoalForm(state) {
      state.openGoalForm = true
    },
    closeGoalForm(state) {
      state.openGoalForm = false
    },
    resetState(state) {
      state.goal = initialState.goal
      state.objectiveType = initialState.objectiveType
      state.openGoalForm = initialState.openGoalForm
    },
    setEditGoalValue(state, action: PayloadAction<Goal>) {
      state.goal = action.payload
    },
  },
})

export const useTreatmentPlanSlice = () => {
  const { actions } = treatmentPlanSlice
  const dispatch = useDispatch()
  const setObjectiveType = (objectiveType: string) => {
    dispatch(actions.setObjectiveType(objectiveType))
  }
  const addObjectives = (objective: Objective) => {
    dispatch(actions.addObjectives(objective))
  }
  const updateObjective = (args: { value: Objective; index: number }) => {
    dispatch(actions.updateObjective(args))
  }
  const hardDeleteObjective = (index: number) => {
    dispatch(actions.hardDeleteObjective(index))
  }
  const softDeleteObjective = (index: number) => {
    dispatch(actions.softDeleteObjective(index))
  }
  const updateGoal = (goal: Partial<Omit<Goal, 'objectives'>>) => {
    dispatch(actions.updateGoal(goal))
  }
  const resetState = () => {
    dispatch(actions.resetState())
  }
  const setEditGoalValue = (goal: Goal) => {
    dispatch(actions.setEditGoalValue(goal))
  }

  const setProfileId = (id: number) => {
    dispatch(actions.setProfileId(id))
  }

  return {
    setObjectiveType,
    addObjectives,
    updateObjective,
    hardDeleteObjective,
    softDeleteObjective,
    updateGoal,
    resetState,
    setEditGoalValue,
    dispatch,
    actions,
  }
}

export const treatmentPlanActions = treatmentPlanSlice.actions
export const treatmentPlaneReducer = treatmentPlanSlice.reducer
