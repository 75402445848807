import { format } from 'date-fns'

type Options = {
  date: string
  formatType?: string
}
export const dateFormatter = ({ date, formatType = 'PPpp' }: Options) => {
  try {
    return format(new Date(date), formatType)
  } catch (error) {
    console.log(error)
    return null
  }
}
