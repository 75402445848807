const therapistRoot = 'therapists'
const userRoot = 'users'
/**
 * Initializing API routes, one centralized place for all API routes
 * Easier to maintain and update
 * */
export const apiRoutes = {
  parent: {
    addChild: 'parents/add-child-profile/',
    afterDiscovery: 'parents/send-email-after-discovery-call/',
  },
  therapist: {
    create: `${therapistRoot}/create/`,
    get: `${therapistRoot}/`,
  },
  user: {
    checkEmailForAuthenticated: `${userRoot}/check-mail-authenticated/`,
  },
  getSessionLengthPrice: 'calcom/get_variable_sessions/',
  submitFeedback: 'calcom/sessions/feedback/',
  landing: {
    failConsultation: 'landing/fail-consultation/',
  },
  createGoal: 'treatment_plan/create/goal/',
  updateGoal: 'treatment_plan/update/goal/',
  getTreatmentPlans: 'treatment_plan',
  createProgress: 'treatment_plan/create/progress/',
  getObjectives: 'treatment_plan/get/objectives',
  getProgress: 'treatment_plan/get/progress',
}
