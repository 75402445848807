import { Modal } from '@mui/material'
import { Box, Flex, Text, Button } from 'components/Core'

interface ConfirmationModalProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  title: string
  message: string
  confirmText?: string
  cancelText?: string
}

export const ConfirmationModal = ({ open, onClose, onConfirm, title, message, confirmText = 'Confirm', cancelText = 'Cancel' }: ConfirmationModalProps) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        position={'absolute'}
        top={'50%'}
        left={'50%'}
        backgroundColor={'white'}
        borderRadius={'8px'}
        boxShadow={24}
        p={4}
        minWidth={300}
        maxWidth={500}
        style={{
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Flex flexDirection="column" gap={3}>
          <Text variant="heading4">{title}</Text>
          <Text>{message}</Text>

          <Flex justifyContent="flex-end" gap={2} mt={2}>
            <Button variant="primary" onClick={onClose}>
              {cancelText}
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                onConfirm()
                onClose()
              }}
            >
              {confirmText}
            </Button>
          </Flex>
        </Flex>
      </Box>
    </Modal>
  )
}
