import { Autocomplete, IconButton, Modal, TextField } from '@mui/material'
import { Spinner } from 'components/ChatSystem/Common/Spinner'
import { Box, Button, CenterRow, Container, Flex, Text } from 'components/Core'
import { objectiveTypes, qualitativeSelectOptions } from 'constants/index'
import { ObjectivesSelector } from 'pages/TherapistPages/TreatmentPlan/ObjectiveSelector'
import { useCreateProgressMutation, useGetObjectivesQuery, useGetProgressQuery } from 'pages/TherapistPages/TreatmentPlan/slice/services'
import type { Objective, Progress } from 'pages/TherapistPages/TreatmentPlan/slice/types'
import { useEffect, useMemo, useState } from 'react'
import { CloseIcon } from 'theme/assets/icons'

type Props = {
  open: boolean
  onClose: () => void
  profile_id: number
  session_id: number
}
type UpdateProgressPayload = { index: number; value: Partial<Progress> }
export const ProgressUpdateModal = ({ onClose, open, profile_id, session_id }: Props) => {
  const [progresses, setProgresses] = useState<Partial<Progress>[]>([])
  const { data: objectives } = useGetObjectivesQuery(profile_id, { refetchOnMountOrArgChange: true, skip: !open })

  const [create, { isLoading, error }] = useCreateProgressMutation()
  const { data, isLoading: fetchingProgress } = useGetProgressQuery(session_id, {
    skip: !open,
  })
  useEffect(() => {
    setProgresses(data || [])
  }, [data, open])

  const handleClose = () => {
    onClose()
    setProgresses([])
  }
  const updateProgress = ({ index, value }: UpdateProgressPayload) => {
    const newProgresses = [...progresses]

    newProgresses[index] = { ...newProgresses[index], ...value }
    if (value.objective?.type === objectiveTypes.QUANTITATIVE) {
      newProgresses[index] = { ...newProgresses[index], qualitative_value: null }
    }
    if (value.objective?.type === objectiveTypes.QUALITATIVE) {
      newProgresses[index] = { ...newProgresses[index], quantitative_value: null }
    }
    setProgresses(newProgresses)
  }
  console.log({ progresses })
  const selectedObjectives = useMemo(() => progresses.map((progress) => progress?.objective?.id), [progresses])
  const prepareProgressToUpdate = useMemo(() => {
    return progresses
      .map((progress) => ({
        session_id,
        objective_id: progress?.objective?.id,
        quantitative_value: progress?.quantitative_value,
        qualitative_value: progress?.qualitative_value,
        progress_id: progress?.id,
      }))
      .filter((progress) => !(progress?.progress_id && !progress?.objective_id))
  }, [progresses])
  return (
    <Modal open={open} onClose={handleClose}>
      <Flex minHeight={300} flexDirection="column" gap={20} variant="modal-container">
        <CenterRow justifyContent={['space-between']}>
          <Text variant="heading3">Progress Update</Text>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </CenterRow>
        {!fetchingProgress ? (
          <Flex px={3} flexDirection="column" gap={20} width="100%" maxHeight={700} overflowY="auto">
            {!fetchingProgress && progresses.length === 0 && (
              <CenterRow py={20} flexDirection="column" gap={16}>
                <Text>No progress recorded yet!</Text>
                <Button
                  onClick={() => {
                    setProgresses((prev) => [...prev, {}])
                  }}
                >
                  Add Progress
                </Button>
              </CenterRow>
            )}

            {progresses?.map((progress, index) => (
              <Flex mt={2} key={index} width="100%" flexDirection="column" gap={16}>
                <ObjectivesSelector
                  disabled={Boolean(progress.id)}
                  objectives={objectives}
                  updateProgress={(objective: Objective) => {
                    updateProgress({
                      index,
                      value: { objective },
                    })
                  }}
                  selectedObjectives={selectedObjectives}
                  modalOpened={open}
                  objectiveValue={progress?.objective}
                  profile_id={profile_id}
                />
                {progress?.objective?.type === objectiveTypes.QUANTITATIVE && (
                  <TextField
                    label="Current Level %"
                    value={progress?.quantitative_value}
                    onChange={(e) =>
                      updateProgress({
                        index,
                        value: { quantitative_value: e.target.value },
                      })
                    }
                    type="number"
                  />
                )}
                {progress?.objective?.type === objectiveTypes.QUALITATIVE && (
                  <Autocomplete
                    options={qualitativeSelectOptions}
                    renderOption={(props, option) => <li {...props}>{option.value}</li>}
                    // value={qualitativeSelectOptions.find((option) => option.value === qualitativeValue)}
                    getOptionLabel={(option) => option.value ?? ''}
                    onChange={(e, value) =>
                      updateProgress({
                        index,
                        value: { qualitative_value: value?.value },
                      })
                    }
                    renderInput={(params) => <TextField {...params} label="Improvement" />}
                    value={{ value: progress?.qualitative_value ?? '' }}
                  />
                )}
              </Flex>
            ))}
            {progresses.length && progresses.length < Number(objectives?.length) ? (
              <CenterRow>
                <Button
                  onClick={() => {
                    setProgresses((prev) => [...prev, {}])
                  }}
                  variant="secondary"
                >
                  Add another progress
                </Button>
              </CenterRow>
            ) : null}
            {progresses.length ? (
              <CenterRow gap={10} justifyContent={['flex-end']}>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button
                  onClick={() => {
                    create({
                      data: prepareProgressToUpdate,
                      closeModal: onClose,
                    })
                  }}
                >
                  {isLoading ? <Spinner width="25px" /> : data?.length ? 'Update' : 'Create'}
                </Button>
              </CenterRow>
            ) : null}
          </Flex>
        ) : (
          <Container flexGrow={1}>
            <Spinner width="30px" />
          </Container>
        )}
      </Flex>
    </Modal>
  )
}
