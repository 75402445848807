// import { Chip } from '@mui/material'
import type { Goal } from './slice/types'
import { Box, Button, CenterRow, Chip, type ChipTypes, Flex, Text } from 'components/Core'
import { goalStatus, objectiveStatus } from 'constants/index'
import { useCallback, useMemo } from 'react'
import { Progresses } from './Progresses'
import { Edit } from '@mui/icons-material'

type Props = {
  goal: Goal

  setEditGoalValue: (goal: Goal) => void
}
export const GoalList = ({ goal, setEditGoalValue }: Props) => {
  const objectiveStatusColor = useCallback((status: string | undefined): ChipTypes | undefined => {
    if (status === objectiveStatus.NOT_STARTED) {
      return 'error'
    }
    if (status === objectiveStatus.IN_PROGRESS) {
      return 'warning'
    }
    if (status === objectiveStatus.COMPLETED) {
      return 'success'
    }
  }, [])
  const goalChipType = useMemo<ChipTypes>(() => {
    if (goal.status === goalStatus.ACCOMPLISHED) {
      return 'success'
    }
    return 'warning'
  }, [goal.status])
  return (
    <>
      <CenterRow borderRadius={1} bg="gray.100" p={3} flexDirection={['column']} width="100%" gap={'10px'} justifyContent={['space-between']}>
        <Box width="100%" pb={2} borderBottom={1}>
          <CenterRow width="100%" justifyContent={['space-between']}>
            <CenterRow gap={2}>
              <Text borderRight={1} pr={2} fontSize={25} fontWeight="bold">
                {goal.title}
              </Text>
              <Chip value={goal.status} type={goalChipType} />
            </CenterRow>
            <Button onClick={() => setEditGoalValue(goal)} fontSize={16} variant="ghost">
              <Edit />
              <Text> Edit</Text>
            </Button>
          </CenterRow>
          <CenterRow width="100%" justifyContent={['space-between']}>
            <Text maxWidth="90%" color="textLightGray" variant="body2">
              {goal.description}
            </Text>
          </CenterRow>
        </Box>
        <Flex pl={3} width="100%" flexDirection={['column']}>
          <CenterRow mb={2} width="100%">
            <Text borderBottom={1} textAlign="start" fontSize={20} color="black.700">
              Objectives ({goal?.objectives?.length})
            </Text>
          </CenterRow>
          {goal.objectives.map((objective, index) => (
            <Flex pl={2} gap={[2, 2, 1]} flexDirection={['column']} key={index} mt={2} width="100%" justifyContent={['space-between']}>
              <Text fontSize={18} color="black.700">
                {objective.title}
              </Text>
              <CenterRow gap={10} flexWrap="wrap" width="100%" justifyContent={['space-between']}>
                <Flex gap={2} flexDirection="column">
                  <Text textDecoration={objective.description ? 'none' : 'line-through'} color="textLightGray">
                    {objective.description || 'No Description'}
                  </Text>
                </Flex>
                <CenterRow gap={2}>
                  <Chip type={objectiveStatusColor(objective.status)} value={objective.status} />
                  <Chip type="info" value={`${objective.progresses?.length} sessions`} />
                </CenterRow>
              </CenterRow>
              <CenterRow mt={2} flexWrap="wrap" width="100%" justifyContent={['space-between']}>
                <Progresses
                  progresses={objective.progresses}
                  type={objective.type}
                  baselinePercentage={objective.baseline_percentage}
                  targetPercentage={objective.target_percentage}
                  objectiveCreatedAt={objective.created_at as string}
                />
              </CenterRow>
            </Flex>
          ))}
        </Flex>
      </CenterRow>
    </>
  )
}
