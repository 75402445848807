import { LineChart } from '@mui/x-charts/LineChart'
import type { Progress } from './slice/types'
import { useMemo } from 'react'
import { dateFormatter } from 'utils/utils'
import { theme } from 'utils/theme'

type Props = {
  baselinePercentage: number | undefined
  targetPercentage: number | undefined
  progresses: Progress[] | undefined
  objectiveCreatedAt: string
}

export function QuantitativeProgressChart({ baselinePercentage, progresses, targetPercentage, objectiveCreatedAt }: Props) {
  const dataset = useMemo(() => {
    return (
      progresses
        ?.filter((progress) => progress.quantitative_value != null)
        ?.map((progress) => ({
          x: dateFormatter({ date: progress.session.date, formatType: 'PP' }),
          y: Number(progress.quantitative_value),
          baseline: baselinePercentage,
          target: targetPercentage,
        })) || []
    )
  }, [progresses, baselinePercentage, targetPercentage])

  return (
    <LineChart
      dataset={[
        {
          x: dateFormatter({ date: objectiveCreatedAt, formatType: 'PP' }) as string,
          y: Number(baselinePercentage),
          baseline: baselinePercentage,
          target: targetPercentage,
        },
        ...dataset,
      ]}
      xAxis={[
        {
          dataKey: 'x',
          scaleType: 'point',
        },
      ]}
      yAxis={[
        {
          min: 0,
          max: 100,
        },
      ]}
      series={[
        { dataKey: 'baseline', label: 'Baseline', color: theme.colors.primary[800], showMark: false },
        { dataKey: 'y', label: 'Current Progress', color: theme.colors.stateSuccess },
        { dataKey: 'target', label: 'Target', color: theme.colors.typePurpure[300], showMark: false },
      ]}
      height={300}
      // margin={{ left: 30, right: 30, top: 30, bottom: 30 }}
      // grid={{ vertical: true, horizontal: true }}
      slotProps={{
        legend: {
          direction: 'row',
          position: { vertical: 'top', horizontal: 'middle' },
          padding: 0,
        },
      }}
    />
  )
}
